/**
 *  * This file is part of FourthStar Checkout Page
 *------------------------------------------------------
 *@module InitiatePayment
 *@developer Shiv Pandey
 */

import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { GlobalStore } from "store/GlobalStore";

import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
import abiJson from "constant/abi-json";
import Loader from "components/Loader/Loader";
import ShadowFrame from "components/shadow-frame";

import Alert from "components/popup/Alert/Alert";

const InitiatePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  const [searchParams] = useSearchParams();

  const productType = searchParams.get("productType");
  const productName = searchParams.get("productName");
  const amount = searchParams.get("amount");
  const productId = searchParams.get("productId");
  const themeId = searchParams.get("themeId");
  const isSubscription = searchParams.get("isSubscription");
  const channelId = searchParams.get("channelId");
  const walletId = searchParams.get("walletId");
  // this checks for paid content, if we are not passing required fields in search params then go back, unless its for cart or subscription
  if (
    !searchParams.get("isCart") &&
    !searchParams.get("isSubscription") &&
    (!productType || !amount || !productId || !walletId)
  ) {
    navigate(-1);
  }

  const selectedAccountRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [alertSuccess, setAlertSuccess] = useState(true);
  const [showChangeWalletErrorPopup, setShowChangeWalletErrorPopup] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);
  // Go to checkout page
  const goToCheckoutPage = async (orderId) => {
    console.log("Checkout page is called");
    navigate(`/checkoutfstr`, {
      replace: true,
      state: {
        orderId: orderId,
        isSubscription: isSubscription ? true : false,
      },
    });
  };

  // Initiate again transaction after change the wallet address
  const modifyCryptoTransactionWalletId = async (
    web3FromAddress,
    paymentHash,
    orderId
  ) => {
    // payload for checking out a single paid product
    try {
      let payload = {
        paymentHash: paymentHash,
        web3FromAddress: web3FromAddress,
      };
      let res = await fetch(
        BASE_URL + EndpointsSlug.MODIFY_CRYPTO_TRANSACTION,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }
      if (res?.response?.statusEnum != 1) {
        setAlertMessage(res.response?.message);
        setAlertSuccess(false);
        setShowChangeWalletErrorPopup(true);
        // throw new Error(res.response?.message);
      } else {
        const existingData = sessionStorage.getItem(`${orderId}`);

        let parsedData = JSON.parse(existingData);
        delete parsedData.qrData;
        // Update fields as needed
        parsedData.gatewayTransaction = res?.response?.data.gatewayTransaction;
        sessionStorage.setItem(`${orderId}`, JSON.stringify(parsedData));
        return true;
      }
    } catch (error) {
      setAlertMessage("Something went wrong please try again!");
      setAlertSuccess(false);
      setShowChangeWalletErrorPopup(true);
      return;
    }
  };

  // this will handle getting user account details, and handle multiple accounts scenarios
  const initiateTransaction = async () => {
    console.log("initiate transaction called");
    // 2. call our backend's initiate trans api here, and store the hash,
    if (isSubscription) {
      await inititateSubscriptonTransactionOnBackend();
    } else {
      // this will return true or false for successful initiation
      await initiateTransactionOnBackend();
    }
  };

  // this will initiate transaction on our backend and we will store required values in localstorage here
  const initiateTransactionOnBackend = async () => {
    try {
      let res = null;
      if (productType === "event") {
        res = await fetch(
          BASE_URL + EndpointsSlug.INITIATE_CRYPTO_EVENT_PURCHASE,
          {
            method: "POST",
            body: JSON.stringify({
              eventId: productId,
              web3FromAddress: localStorage.getItem("web3FromAddress"),
            }),
            mode: "cors",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        let product = {
          amount: Number(amount),
          productId: productId,
          productType: productType,
          name: productName,
        };

        // for apartments and ships
        if (themeId) {
          product = {
            ...product,
            themeId,
          };
        }

        // payload for checking out a single paid product
        let payload = {
          products: [product],
          paymentGatewayName: "polygon",
          total_amount: Number(amount),
          web3FromAddress: localStorage.getItem("web3FromAddress"),
        };

        // if we are checking out a cart, we are getting the payload stored in session storage from the cart page and usingit here
        if (searchParams.get("isCart")) {
          console.log("cart checkout");
          payload = {
            products: JSON.parse(
              sessionStorage.getItem("cartCheckoutDataCrypto")
            ),
            paymentGatewayName: "polygon",
            total_amount: JSON.parse(
              sessionStorage.getItem("cartCheckoutDataCrypto")
            ).reduce((acc, current) => {
              return acc + current.amount;
            }, 0),
            web3FromAddress:
              selectedAccountRef.current ||
              localStorage.getItem("web3FromAddress"),
          };
        }

        res = await fetch(
          BASE_URL + EndpointsSlug.INITIATE_CRYPTO_TRANSACTION,
          {
            method: "POST",
            body: JSON.stringify(payload),
            mode: "cors",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (res === null) {
        throw new Error("Something went wrong!");
      }

      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum == 0 && res?.response?.errorCode == "210") {
        console.log("Already", res.response?.message);
        setShowAlert(true);
        setAlertSuccess(true);
        setIsLoading(false);
        setAlertMessage("you have already purchased this Asset!");
        return res;
      } else if (
        res?.response?.statusEnum == 0 &&
        res?.response?.errorCode == "288"
      ) {
        console.log("Purchase limit", res.response?.message);
        setShowAlert(true);
        setAlertSuccess(false);
        setIsLoading(false);
        setAlertMessage(
          res.response?.message || "Purchase limit exceeded for this asset"
        );
        return res;
      } else if (
        res?.response?.statusEnum == 0 &&
        res?.response?.errorCode == "216"
      ) {
        const web3FromAddress =
          res?.response?.data?.gatewayTransaction?.web3FromAddress;
        const paymentHash =
          res?.response?.data?.gatewayTransaction?.paymentHash;
        const orderId = res?.response?.data?.gatewayTransaction?._id;
        if (web3FromAddress.toLowerCase() !== walletId.toLowerCase()) {
          await modifyCryptoTransactionWalletId(walletId, paymentHash, orderId);
          await goToCheckoutPage(orderId);
        } else {
          await goToCheckoutPage(orderId);
          console.log("Your order is pending");
        }
      } else {
        let responseData = res?.response?.data;
        let orderId = res?.response?.data?.gatewayTransaction?._id;
        const expiryTime = new Date().getTime() + 7 * 60 * 1000; // Current time + 10 minutes
        let sessionInitiateTransactionData = {
          ...responseData,
          expiryTime,
          productType,
          productId,
        };

        sessionStorage.setItem(
          `${orderId}`,
          JSON.stringify(sessionInitiateTransactionData)
        );

        await goToCheckoutPage(orderId);
        // localStorage.setItem(
        //   "transactionpaymenthashfstr",
        //   res?.response?.data?.gatewayTransaction?.paymentHash
        // );
        // selectedAccountRef.current =
        //   res?.response?.data?.gatewayTransaction?.web3FromAddress;
        // localStorage.setItem(
        //   "web3ToAddress",
        //   res?.response?.data?.gatewayTransaction?.web3ToAddress
        // );
        // returning if initiate succeeded or not
        return res?.response?.statusEnum == 1;

        // setPaymentUrl(res.response?.data?.session?.url);
      }
    } catch (e) {
      setAlertSuccess(false);
      setShowAlert(true);
      setIsLoading(false);
      setAlertMessage(e.message || "Something went wrong");
      return false;
    }
  };

  const inititateSubscriptonTransactionOnBackend = async () => {
    try {
      let payload = {
        channelId,
        web3FromAddress: localStorage.getItem("web3FromAddress"),
      };

      let res = await fetch(
        BASE_URL + EndpointsSlug.INITIATE_CRYPTO_SUBSCRIPTION,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum == 0 && res?.response?.errorCode == "210") {
        console.log("Already", res.response?.message);
        setShowAlert(true);
        setIsLoading(false);
        setAlertMessage("you have already purchased this Asset!");
        return res;
      } else if (
        res?.response?.statusEnum == 0 &&
        res?.response?.errorCode == "216"
      ) {
        const web3FromAddress =
          res?.response?.data?.gatewayTransaction?.web3FromAddress;
        const paymentHash =
          res?.response?.data?.gatewayTransaction?.paymentHash;
        const orderId = res?.response?.data?.gatewayTransaction?._id;
        if (web3FromAddress.toLowerCase() !== walletId.toLowerCase()) {
          await modifyCryptoTransactionWalletId(walletId, paymentHash, orderId);
          await goToCheckoutPage(orderId);
        } else {
          await goToCheckoutPage(orderId);
          console.log("Your order is pending");
        }
      } else {
        let responseData = res?.response?.data;
        let orderId = res?.response?.data?.gatewayTransaction?._id;
        const expiryTime = new Date().getTime() + 7 * 60 * 1000; // Current time + 10 minutes
        let sessionInitiateTransactionData = { ...responseData, expiryTime };

        sessionStorage.setItem(
          `${orderId}-initiateTransaction`,
          JSON.stringify(sessionInitiateTransactionData)
        );

        await goToCheckoutPage(orderId);
        // localStorage.setItem(
        //   "transactionpaymenthashfstr",
        //   res?.response?.data?.gatewayTransaction?.paymentHash
        // );
        // selectedAccountRef.current =
        //   res?.response?.data?.gatewayTransaction?.web3FromAddress;
        // localStorage.setItem(
        //   "web3ToAddress",
        //   res?.response?.data?.gatewayTransaction?.web3ToAddress
        // );
        // returning if initiate succeeded or not
        return res?.response?.statusEnum == 1;

        // setPaymentUrl(res.response?.data?.session?.url);
      }
    } catch (e) {
      setAlertSuccess(false);
      setShowAlert(true);
      setIsLoading(false);
      setAlertMessage(e.message || "Something went wrong");
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      localStorage.setItem("web3FromAddress", walletId);
      await initiateTransaction();
    })();
  }, [walletId]);

  if (isLoading) {
    return (
      <div className="relative w-full min-h-screen flex flex-col justify-center items-center">
        <Loader />

        <p className="text-center mt-6 text-white/50 font-medium">
          Processing Transaction
        </p>

        <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      </div>
    );
  }

  return (
    <div className="relative min-h-screen w-full max-sm:px-0 px-8 ">
      <div className="flex items-center justify-center min-h-screen  px-4"></div>
      {showAlert && (
        <Alert
          open={showAlert}
          message={alertMessage}
          isSuccess={alertSuccess}
          canClose={false}
          onClose={() => {
            setShowAlert(false);
            setIsLoading(false);
            navigate("/marketplace");
          }}
        />
      )}

      {showChangeWalletErrorPopup && (
        <Alert
          open={showChangeWalletErrorPopup}
          message={alertMessage}
          isSuccess={alertSuccess}
          canClose={false}
          onClose={() => {
            setShowAlert(false);
            setIsLoading(false);
            navigate("/marketplace");
          }}
        />
      )}
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </div>
  );
};

export default InitiatePayment;
