/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Sameer <sameer@shadowcast.io>
 */

import React, { useState, useRef, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import tokenInfographic from "assets/images/home/token-infographic.png";
import ProBit_logo from "assets/images/ProBit_Global_Logo_White_RGB.png";
import ProBitTrading from "assets/images/ProBit_Global_Trading.png";

import FSTRCoinFlip from "assets/images/FSTRCoinFlip.png";
import infographic from "assets/images/home/infographic.png";

import { Link, NavLink } from "react-router-dom";

import "./styles.css";

const Index = () => {
  return (
    <>
      <Box className="container-fluid w-[100%] container app-container mx-auto">
        <div className="unlock-text-container !mt-4">
          <img
            src={FSTRCoinFlip}
            alt=""
            className="h-auto w-1/4 sm:w-1/5 lg:w-1/6  rounded-full mb-7"
          />
          <h1 className="hero-text">FSTR</h1>
          <p className="hero-text-desc">
            The Fourth Star Token is the financial bedrock of the Fourth Star
            Metaverse, the ultimate Utility Token that unlocks discounts on
            purchases for Apartments, Ships, Companions and Content. Token
            holders enjoy special ranking and privileges; VIP invites, Red
            Carpet and Early Screenings . Please see example purchase below for
            the ALPHA LEO AI Battle Bot. The Price listed is for illustrative
            purposes only and discount is calculated based on the FSTR Public
            Launch Price of $0.51.
          </p>
          <p className="fstr-text">
            FSTR UTILITY TOKEN - ACCESS HUGE DISCOUNTS
          </p>
          <Link
            to="https://www.probit.com/en-us/app/exchange/FSTR-USDT"
            target="_blank"
            // className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]"
            className=" btn-gradient text-black font-bold rounded-[8px] px-5 py-3 text-center text-[16px] leading-[14.63px]"
          >
            Buy FSTR On ProBit Global
          </Link>
        </div>

        <div className="token-info-container !mt-20">
          <img src={tokenInfographic} alt="" />
        </div>
        <div className="unlock-text-container !mt-32">
          <h1 className="hero-text">FSTR Discounts & the Market </h1>
          <p className="hero-text-desc">
            FSTR will “Pre-exchange” Public Launch at $0.51 and will always be
            worth a minimum of $1 on the platform. As an Example, when
            purchasing FSTR at the Public Launch price of $0.51 and purchasing
            an Item on the Platform, each FSTR will be worth a minimum of $1,
            ensuring a discount on purchases of 49%. When FSTR has a market rate
            of $1 or more, a standard discount of 25% will be applied. Please
            see the following as examples of discounts calculated for varying
            FSTR market price rates. Examples are for illustrative purposes only
            and are not intended as a prediction of market price.
          </p>
          <p className="fstr-text">
            ALWAYS WORTH A MINIMUM OF A $1 AND ONGOING DISCOUNT OF 25%
          </p>
          <Link
            to="https://www.probit.com/en-us/app/exchange/FSTR-USDT"
            target="_blank"
            // className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]"
            className=" btn-gradient text-black font-bold rounded-[8px] px-5 py-3 text-center text-[16px] leading-[14.63px]"
          >
            Buy FSTR On ProBit Global
          </Link>
        </div>

        {/* <div className="unlock-text-container bg-[#1a1a1a] p-10 !ml-[0px] lg:!ml-auto md:p-24 lg:p-32 !w-screen">
          <img
            src={FSTRCoinFlip}
            alt=""
            className="h-auto w-1/4 sm:w-1/5 lg:w-1/6  rounded-full mb-7"
          />
          <h1 className="hero-text fstr-text">
            Fourth Star Utility Token (FSTR) Now Live on ProBit Global!
          </h1>
          <p className="hero-text-desc mx-3 md:mx-10 lg:mx-36 text-gray-400">
            The Fourth Star Token (FSTR) is officially live on ProBit Global!
            <br />
            You can now buy, sell and trade FSTR to unlock exclusive in-platform
            rewards, virtual assets and more.
          </p>
          <img
            src={ProBit_logo}
            alt=""
            className="h-auto w-[200px] rounded-full"
          />
          <div className="flex flex-col m-0 z-30 items-center justify-center">
            <Link
              to="https://www.probit.com/en-us/app/exchange/FSTR-USDT"
              target="_blank"
              className="z-[20] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[16px] leading-[14.63px]"
            >
              Buy FSTR On ProBit Global
            </Link>
            <p className="mt-10 text-center font-semibold z-20">
              Don't have a ProBit Global account?{" "}
              <NavLink
                target="_blank"
                to="https://www.probit.com/r/99268855"
                className="cursor-pointer text-sec hover:text-secDark font-bold"
              >
                Click here{" "}
              </NavLink>
              to create one now!
            </p>
            <Link
              to="https://www.probit.com/r/99268855"
              target="_blank"
              // className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]"
              className="z-[20] ml-4 btn-gradient text-black font-bold rounded-[8px] px-5 py-3 text-center text-[16px] leading-[14.63px]"
            >
              Create ProBit Global Account
            </Link>
          </div>
          <img src={ProBitTrading} alt="" className="h-auto w-full my-[-30%]" />
          <div className="unlock-text-container-new z-10 bg-black !px-10 !w-screen">
            <img
              src={FSTRCoinFlip}
              alt=""
              className="h-auto w-1/4 sm:w-1/5 lg:w-1/6 mt-[-35px] sm:mt-[-70px] lg:mt-[-100px] 2xl:mt-[-140px]"
            />
            <h1 className="font-bold text-[24px] text-center sm:mx-24 leading-10">
              Celebrate FSTR's Launch on ProBit Global- Earn Thousands in our
              Luxury Apartments and Ships Giveaway! 🚀✨
            </h1>

            <p className="hero-text-desc text-gray-400 mb-5">
              We’re Excited to Announce the Launch of FSTR! The wait is over!
              Our utility token, FSTR, is now live on the ProBit Global
              Exchange. <br />
              To celebrate, we’re giving away thousands of dollars in Luxury
              Apartments and Ships to participants in our exclusive giveaway.
            </p>
            <p className="fstr-text">
              The giveaway concludes on January 7th, 2025.
            </p>
            <div className="grid mx-20 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
              <div className="group relative z-10 ">
                <img
                  src={giveaway_1}
                  alt=""
                  className="h-auto w-full object-cover group-hover:scale-105 transition duration-300 ease-in-out"
                />
              </div>
              <div className="group relative z-10 ">
                <img
                  src={giveaway_2}
                  alt=""
                  className="h-auto w-full object-cover group-hover:scale-105 transition duration-300 ease-in-out"
                />
              </div>
              <div className="group relative z-10 ">
                <img
                  src={giveaway_3}
                  alt=""
                  className="h-auto w-full object-cover group-hover:scale-105 transition duration-300 ease-in-out"
                />
              </div>
            </div>
            <h1 className="text-[#00FFFF] font-bold">
              ~ Qualify for Multiple Giveaways ~
            </h1>
            <p className="hero-text-desc text-gray-400">
              You may qualify for all giveaways by holding the requisite FSTR.
              For example, if you want both Explorer and Pioneer then please
              hold 1,250 FSTR and for all three please hold 2,250.
            </p>
            <h1 className="font-semibold">
              Don’t miss your chance to win luxury spaceships and exclusive
              apartments in the Fourth Star Metaverse!
            </h1>
            <Link
              to="https://offers.fourthstar.com/probit-launch?utm_source=fourthstar&utm_campaign=Probit_Exchange_Launch_Giveaway_1&utm_medium=website"
              target="_blank"
              // className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]"
              className=" btn-gradient text-black font-bold rounded-[8px] px-5 py-3 mt-2 mb-10 text-center text-[16px] leading-[14.63px]"
            >
              Subscribe Now
            </Link>
          </div>
        </div> */}

        {/* <div className="unlock-text-container">
          <h1 className="hero-text">FSTR Discounts & the Market</h1>
          <p className="hero-text-desc text-gray-400">
            FSTR will “Pre-exchange” Public Launch at $0.51 and will always be
            worth a minimum of $1 on the platform. As an Example, when
            purchasing FSTR at the Public Launch price of $0.51 and purchasing
            an Item on the Platform, each FSTR will be worth a minimum of $1,
            ensuring a discount on purchases of 49%. When FSTR has a market rate
            of $1 or more, a standard discount of 25% will be applied. Please
            see the following as examples of discounts calculated for varying
            FSTR market price rates. Examples are for illustrative purposes only
            and are not intended as a prediction of market price.
          </p>
          <p className="fstr-text">
            ALWAYS WORTH A MINIMUM OF A $1 AND ONGOING DISCOUNT OF 25%
          </p>
          <Link
            to="https://www.probit.com/en-us/app/exchange/FSTR-USDT"
            target="_blank"
            // className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]"
            className=" btn-gradient text-black font-bold rounded-[8px] px-5 py-3 text-center text-[16px] leading-[14.63px]"
          >
            Buy FSTR On ProBit Global
          </Link>
        </div> */}

        <div className="layout-info-container">
          <img src={infographic} alt="" />
        </div>

        <div className="unlock-text-container container app-container mx-auto !mb-0 bg-[#1a1a1a] p-10 !ml-[0px] lg:!ml-auto md:p-24 lg:p-32 !w-screen">
          {/* <img
            src={FSTRCoinFlip}
            alt=""
            className="h-auto w-1/4 sm:w-1/5 lg:w-1/6  rounded-full mb-7"
          /> */}
          <h1 className="hero-text fstr-text">
            Fourth Star Utility Token (FSTR) Now Live on ProBit Global!
          </h1>
          <p className="hero-text-desc mx-3 md:mx-10 lg:mx-36 text-gray-400">
            The Fourth Star Token (FSTR) is officially live on ProBit Global!
            <br />
            You can now buy, sell and trade FSTR to unlock exclusive in-platform
            rewards, virtual assets and more.
          </p>
          <img
            src={ProBit_logo}
            alt=""
            className="h-auto w-[200px] rounded-full"
          />
          <div className="flex flex-col m-0 z-30 items-center justify-center">
            <Link
              to="https://www.probit.com/en-us/app/exchange/FSTR-USDT"
              target="_blank"
              className="z-[20] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[16px] leading-[14.63px]"
            >
              Buy FSTR On ProBit Global
            </Link>
            <p className="mt-10 text-center font-semibold z-20">
              Don't have a ProBit Global account?{" "}
              <NavLink
                target="_blank"
                to="https://www.probit.com/r/99268855"
                className="cursor-pointer text-sec hover:text-secDark font-bold"
              >
                Click here{" "}
              </NavLink>
              to create one now!
            </p>
          </div>
          <img src={ProBitTrading} alt="" className="h-auto w-full my-[-30%]" />
        </div>
      </Box>
    </>
  );
};

export default Index;
