/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Sameer <sameer@shadowcast.io>
 */

// /* eslint-disable no-unused-vars */

import Home from "pages/home/Index";
import Genevieve from "pages/genevieve/Index";
import FSTRPage from "pages/fstr/Index";
import Marketplace from "pages/marketplace/Index";
import ApartmentPage from "pages/marketplace/apartments/Index";
import ShipPage from "pages/marketplace/ships/Index";
import ClothingPage from "pages/marketplace/clothing/Index";
import CompanionPage from "pages/marketplace/companions/Index";
import CompanionsList from "pages/marketplace/companions/CompanionsList";
import CompanionDetail from "pages/marketplace/companions/detail/Index.jsx";
import AvatarPage from "pages/marketplace/avatars/Index";
import GamingPage from "pages/marketplace/games/Index";
import ApartmentDetail from "pages/marketplace/apartments/detail/Index.jsx";
import ClothingDetail from "pages/marketplace/clothing/detail/Index.jsx";
import AvatarDetail from "pages/marketplace/avatars/detail/Index.jsx";
import ShipsDetail from "pages/marketplace/ships/detail/Index.jsx";
import EntertainmentPage from "pages/marketplace/entertainment/Index";
import EventsPage from "pages/marketplace/entertainment/events/Events";
// import EntertainmentChannel from 'pages/marketplace/entertainment/channel/Index.jsx';
import SignUp from "pages/auth/signup";
import Layout from "layout/Index.jsx";
import AuthLayout from "AuthLayout/index";
import BeforeLoginLayout from "BeforeLoginLayout/index";
import SignIn from "pages/auth/signin";
import ForgotPassword from "pages/auth/forgot-password";
import OtpVerification from "pages/auth/forgot-password/otp-verification";
import ConfirmPassword from "pages/auth/forgot-password/confirm-password";
import { MyAssets } from "pages/myassets";
import Subscriptions from "pages/subscriptions/Index";
import InviteHome from "pages/friends/InviteHome";
import MyProfile from "pages/myprofile/MyProfile";
import EntertainmentVideo from "pages/marketplace/entertainment/list/Index";
import ChannelContent from "pages/marketplace/entertainment/channel/Index";
import EntertainmentContent from "pages/marketplace/entertainment/content/Index";
// import MarketDetailPage from "components/detailPage/MarketDetailPage"; // for clothing and avatar
import MusicContent from "pages/marketplace/entertainment/content/Music";
import GameCategory from "pages/marketplace/games/Category/GameCategory";
import AddDetails from "pages/auth/signup/addDetails/Index";
import SelectPreferences from "pages/auth/signup/selectPreferences/Index";
import AddTags from "pages/auth/signup/addTags/Index";
import OnBoardingComplete from "pages/auth/signup/onBoardingComplete/Index";
import { Provider } from "react-redux";
import { LocalStore } from "store/LocalStore";
import ProtectedRoute from "services/auth/ProtectedRoute";
import MyTransaction from "pages/mytransaction/MyTransaction";
import MyPreference from "pages/myprofile/preference/MyPreference";
import GameCategoryDetail from "pages/marketplace/games/detail/GameCategoryDetail";
import MyNotification from "pages/notification/MyNotification";
import SingleNotification from "pages/notification/detail/SingleNotification";
import Cart from "pages/cart/Cart";
import EventDetail from "pages/marketplace/entertainment/events/detail/EventDetail";
// import { GlobalStore } from "store/GlobalStore";
import AvatarsList from "pages/marketplace/avatars/AvatarsList";
import Checkout from "pages/checkout/Checkout";
import CheckoutFSTR from "pages/checkout/CheckoutFSTR";
import InitiatePayment from "pages/checkout/InitiatePayment";
import CheckoutSuccess from "pages/checkout/Success";
import CheckoutFailed from "pages/checkout/Failed";
import Wallet from "pages/wallet/Index";
// import { Ships } from 'components/assets/myassets';
import PrivacyPolicy from "pages/privacyPolicy/Index";
import CookiePolicy from "pages/cookiePolicy/Index";
import TermCondition from "pages/termCondition/Index";
import SideQuest from "pages/sidequest/Index";
import HelpCenter from "pages/helpCenter";
import RequestTicket from "pages/helpCenter/requestTicket";
import ReportBug from "pages/helpCenter/reportBug";
import RequestFeature from "pages/helpCenter/requestFeature";
import RefundCancellationPolicy from "pages/refundCancellationPolicy'";
import AlreadyInAsset from "pages/checkout/AlreadyInAsset";
import ListTickets from "pages/helpCenter/listTickets";
import Details from "pages/helpCenter/details";

/* The code is exporting a default function that returns an array of route objects. Each route object
represents a specific route in a web application. */
// eslint-disable-next-line
// export default function () {
const routes = [
  {
    name: "Home",
    key: "home",
    route: "/",
    component: <Home />,
    useLayout: <Layout />,
  },
  {
    name: "Genevieve",
    key: "genevieve",
    route: "/genevieve",
    component: <Genevieve />,
    useLayout: <Layout />,
  },
  {
    name: "FSTR",
    key: "fstr",
    route: "/fstr",
    component: <FSTRPage />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace",
    key: "marketplace",
    route: "/marketplace",
    component: <Marketplace value="0" element={<ApartmentPage />} />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Apartments",
    key: "marketplace-apartments",
    route: "/marketplace/apartments",
    component: <Marketplace value="0" element={<ApartmentPage />} />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Apartments-Details",
    key: "marketplace-apartments-details",
    route: "/marketplace/apartments/:apartmentId/:apartment_name",
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="0" element={<ApartmentDetail />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Ships",
    key: "marketplace-ships",
    route: "/marketplace/ships",
    component: <Marketplace value="1" element={<ShipPage />} />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Ships-Details",
    key: "marketplace-ships-details",
    route: "/marketplace/ships/:shipid/:ship_name",
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="1" element={<ShipsDetail />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Entertainment",
    key: "marketplace-entertainment",
    route: `/marketplace/entertainment/`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<EntertainmentPage />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },

  {
    name: "Marketplace-Entertainment",
    key: "marketplace-entertainment",
    route: `/marketplace/entertainment/:activeTab`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<EntertainmentVideo />} />
      </Provider>
    ),
    // component: <EntertainmentVideo value="2" />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Events",
    key: "marketplace-events",
    route: `/marketplace/entertainment/events/:activeTab`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<EventsPage />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Event-Details",
    key: "marketplace-event-details",
    route: `/marketplace/entertainment/event/:id`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<EventDetail />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Entertainment",
    key: "marketplace-entertainment",
    // route: `/marketplace/entertainment/:type/:channelId`,
    route: `/marketplace/entertainment/channels/:channelId`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<ChannelContent />} />,
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Entertainment",
    key: "marketplace-entertainment",
    // route: `/marketplace/entertainment/:type/:channelId`,
    route: `/marketplace/entertainment/channels/:channelId/:tabId`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<ChannelContent />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  // {
  //   name: 'Marketplace-Channel',
  //   key: 'marketplace-channel',
  //   route: `/marketplace/entertainment/:type/:channelId`,
  //   component: <EntertainmentChannel />,
  //   useLayout: <Layout />,
  // },

  {
    name: "Marketplace-Entertainment",
    key: "marketplace-entertainment",
    // route: `/marketplace/entertainment/music/content`,
    route: `/marketplace/entertainment/content/music/:channelId/:contentId`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<MusicContent />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Channel",
    key: "marketplace-channel",
    // route: `/marketplace/entertainment/:type/:channelId/:channelContentId`,
    route: `/marketplace/entertainment/content/:type/:channelId/:contentId`,
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="2" element={<EntertainmentContent />} />
      </Provider>
    ),
    // component: <EntertainmentChannel />,
    useLayout: <Layout />,
  },

  {
    name: "Marketplace-Clothing",
    key: "marketplace-clothing",
    route: "/marketplace/clothing",
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="4" element={<ClothingPage />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Clothing-Detail",
    key: "marketplace-clothing-detail",
    route: "/marketplace/clothing/:categorytype/:item",
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="4" element={<ClothingDetail />} />
      </Provider>
    ),
    // component: <MarketDetailPage id="Clothing" value="3" />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Games",
    key: "marketplace-games",
    route: "/marketplace/gaming",
    component: <Marketplace value="6" element={<GamingPage />} />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Games-Category",
    key: "marketplace-games-category",
    route: "/marketplace/gaming/:category",
    component: <GameCategory value="6" defaultRoute="all" />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Games-Category-Detail",
    key: "marketplace-games-category-detail",
    route: "/marketplace/gaming/:category/:item",
    component: <Marketplace value="6" element={<GameCategoryDetail />} />,
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Companions",
    key: "marketplace-companions",
    route: "/marketplace/companions",
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="5" element={<CompanionPage />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Companions-Category-Wise-List",
    key: "marketplace-companions-category-wise-list",
    route: "/marketplace/companions/:categorytype/",
    component: (
      <Provider store={LocalStore}>
        <Marketplace id="Companions" value="5" element={<CompanionsList />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Companions-Detail",
    key: "marketplace-companions-detail",
    route: "/marketplace/companions/:categorytype/:item",
    component: (
      <Provider store={LocalStore}>
        <Marketplace id="Companions" value="5" element={<CompanionDetail />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Avatars",
    key: "marketplace-Avatars",
    route: "/marketplace/avatars",
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="3" element={<AvatarPage />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Avatars-Detail",
    key: "marketplace-avatars-detail",
    route: "/marketplace/avatars/:categorytype/:item",
    component: (
      <Provider store={LocalStore}>
        <Marketplace value="3" element={<AvatarDetail />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Marketplace-Avatars-Detail",
    key: "marketplace-avatars-detail",
    route: "/marketplace/avatars/:categorytype/",
    component: (
      <Provider store={LocalStore}>
        <Marketplace id="Avatars" value="3" element={<AvatarsList />} />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Create-Account",
    key: "Create-Account",
    route: "/signup",
    component: <SignUp />,
    useLayout: <BeforeLoginLayout />,
  },
  {
    name: "Add-Details",
    key: "Add-Details",
    route: "/add-details",
    component: (
      <Provider store={LocalStore}>
        <AddDetails />
      </Provider>
    ),
    useLayout: <AuthLayout />,
  },
  {
    name: "Select-Preferences",
    key: "Select-Preferences",
    route: "/select-preferences",
    component: (
      <Provider store={LocalStore}>
        <SelectPreferences />
      </Provider>
    ),
    useLayout: <AuthLayout />,
  },
  {
    name: "Add-Tags",
    key: "Add-Tags",
    route: "/add-tags",
    component: (
      <Provider store={LocalStore}>
        <AddTags />
      </Provider>
    ),
    useLayout: <AuthLayout />,
  },
  {
    name: "Onboarding-Complete",
    key: "Onboarding-Complete",
    route: "/onboarding-complete",
    component: (
      <Provider store={LocalStore}>
        <OnBoardingComplete />
      </Provider>
    ),
    useLayout: <AuthLayout onBoardingComplete={true} />,
  },
  {
    name: "Wallet-Connect-Page",
    key: "Wallet-Connect-Page",
    route: "/wallet",
    component: (
      <Provider store={LocalStore}>
        <Wallet />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Checkout-Page",
    key: "Checkout-Page",
    route: "/checkout",
    component: (
      <Provider store={LocalStore}>
        <Checkout />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Checkout-FSTR-Page",
    key: "Checkout-FSTR-Page",
    route: "/checkoutfstr",
    component: (
      <Provider store={LocalStore}>
        <CheckoutFSTR />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Checkout-Initiate-Payment-Page",
    key: "Checkout-Initiate-Payment-Page",
    route: "/initiatepayment",
    component: (
      <Provider store={LocalStore}>
        <InitiatePayment />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Checkout-Success-Page",
    key: "Checkout-Success",
    route: "/checkout/success",
    component: (
      <Provider store={LocalStore}>
        <CheckoutSuccess />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Checkout-Already-In-Asset",
    key: "Checkout-Already-In-Asset",
    route: "/checkout/already-in-asset",
    component: (
      <Provider store={LocalStore}>
        <AlreadyInAsset />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Checkout-Failed-Page",
    key: "Checkout-Failed-Page",
    route: "/checkout/cancelled",
    component: (
      <Provider store={LocalStore}>
        <CheckoutFailed />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Login",
    key: "Login",
    route: "/signin",
    component: <SignIn />,
    useLayout: <BeforeLoginLayout />,
  },
  {
    name: "OTP-Verify",
    key: "OTP-Verify",
    route: "/otp-verification",
    component: <OtpVerification />,
    useLayout: <AuthLayout />,
  },
  {
    name: "Forgot-Password",
    key: "Forgot-Password",
    route: "/forgot-password",
    component: <ForgotPassword />,
    useLayout: <AuthLayout />,
  },

  {
    name: "Reset-Password",
    key: "Reset-Password",
    route: "/reset-password",
    component: <ConfirmPassword />,
    useLayout: <AuthLayout />,
  },
  {
    name: "MyAssets",
    key: "my-assets",
    route: "/my-assets",
    component: (
      <ProtectedRoute>
        <MyAssets />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Subscriptions",
    key: "subscriptions",
    route: "/my-assets/subscriptions",
    component: (
      <ProtectedRoute>
        <Subscriptions />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "MyFriend",
    key: "my-friend",
    route: "/friends",
    component: (
      <ProtectedRoute>
        <InviteHome />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "MyProfile",
    key: "my-profile",
    route: "/me",
    component: (
      <ProtectedRoute>
        <MyProfile />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "MyTransaction",
    key: "my-transaction",
    route: "/me/transaction",
    component: (
      <ProtectedRoute>
        <MyTransaction />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "Cart",
    key: "cart",
    route: "/cart",
    component: (
      <ProtectedRoute>
        <Cart />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "MyPreference",
    key: "my-preference",
    route: "/me/edit/preference",
    component: (
      <ProtectedRoute>
        <MyPreference />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "MyNotification",
    key: "my-notification",
    route: "/me/all/notification",
    component: (
      <ProtectedRoute>
        <MyNotification />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "MySingleNotification",
    key: "my-single-notification",
    route: "/me/notification/:notifyId",
    component: (
      <ProtectedRoute>
        <SingleNotification />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "PrivacyPolicy",
    key: "privacypolicy",
    route: "/privacy-policy",
    component: <PrivacyPolicy />,
    useLayout: <Layout />,
  },
  {
    name: "CookiePolicy",
    key: "cookiePolicy",
    route: "/cookie-policy",
    component: <CookiePolicy />,
    useLayout: <Layout />,
  },
  {
    name: "TermCondition",
    key: "termcondition",
    route: "/terms-condition",
    component: <TermCondition />,
    useLayout: <Layout />,
  },
  {
    name: "SideQuest",
    key: "sidequest",
    route: "/sidequest",
    component: <SideQuest />,
    useLayout: <Layout />,
  },
  {
    name: "HelpCenter",
    key: "helpcenter",
    route: "/help-center",
    component: <HelpCenter />,
    useLayout: <Layout />,
  },
  {
    name: "HelpCenterTickets",
    key: "helpcentertickets",
    route: "/help-center/ticket-list",
    component: (
      <ProtectedRoute>
        <ListTickets />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "HelpCenterTicketDetails",
    key: "helpcenterticketdetails",
    route: "/help-center/ticket-list/details",
    component: (
      <ProtectedRoute>
        <Details />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "RequestTicket",
    key: "requestticket",
    route: "/request-ticket",
    component: (
      <Provider store={LocalStore}>
        <RequestTicket />
      </Provider>
    ),
    useLayout: <Layout />,
  },
  {
    name: "ReportBug",
    key: "reportbug",
    route: "/report-bug",
    component: (
      <ProtectedRoute>
        <ReportBug />
      </ProtectedRoute>
    ),
    useLayout: <Layout />,
  },
  {
    name: "RequestFeature",
    key: "requestfeature",
    route: "/request-feature",
    component: <RequestFeature />,
    useLayout: <Layout />,
  },
  {
    name: "RefundCancellationPolicy",
    key: "refundcancellation",
    route: "/refund-cancellation-policy",
    component: <RefundCancellationPolicy />,
    useLayout: <Layout />,
  },
];

export { routes };
// }
