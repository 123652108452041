import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const HLSPlayer = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls({
        startLevel: 0, // Start with the lowest quality
        maxBufferLength: 5, // Reduce buffer size for quicker startup
      });
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current.play();
      });
    }
  }, [src]);

  return <video ref={videoRef} controls width="100%" />;
};

export default HLSPlayer;
