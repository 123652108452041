/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Sameer <sameer@shadowcast.io>
 */

import React, { useState, useRef, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import ShadowFrame from "components/shadow-frame";
import LandingBannerImg from "assets/images/genevieve/genevieve.png";
import intro_video_preview from "assets/images/genevieve/intro_video_preview.png";

import heroImage2 from "assets/images/genevieve/genevieve_showcase.png";

import imageMobile from "assets/images/genevieve/genevieveMobile.png";
import imageTab from "assets/images/genevieve/genevieveTab.png";
import genevive_social_1 from "assets/images/genevieve/genevieve_social_1.png";
import genevive_social_2 from "assets/images/genevieve/genevieve_social_2.png";
import genevive_social_3 from "assets/images/genevieve/genevieve_social_3.png";
import gen_last from "assets/images/genevieve/gen_last.png";
import social_hidden from "assets/images/genevieve/social_hidden.png";
import Loader from "components/Loader/Loader";
import { FaFacebook, FaInstagram, FaXTwitter } from "react-icons/fa6";

import "./styles.css";
import { socialLinks } from "constant";

const Index = () => {
  const [bannerImageLoaded, setBannerImageLoaded] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  // SIMPLE VIDEO LINK
  // const video_url = `${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/fs_marketing_hero_section_video.mp4`;
  // STREAMFILE VIDEO LINK
  const video_url = `${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/stream/amlix-1733118976839/master.m3u8`;
  const closePopup = () => {
    // if (canClose) {
    //   if (typeof onClose == "function") {
    //     onClose();
    //   }
    setShowVideoModal(false);
    // }
  };

  const onBannerImageLoad = (ev) => {
    setBannerImageLoaded(true);
  };

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  return (
    <>
      <Box className="container-fluid w-[100%] myFont">
        {!bannerImageLoaded && (
          <div className="relative h-[calc(100vh-50px)] md:h-[450px] lg:h-[550px] xl:h-[700px] 2xl:h-[calc(100vh-50px)] grid place-content-center">
            <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
            <ShadowFrame className="absolute top-2/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
            <Loader />
          </div>
        )}
        <picture className="overflow-hidden">
          <source media="(max-width: 799px)" srcSet={imageMobile} />
          <source media="(max-width: 1200px)" srcSet={imageTab} />
          <img
            src={LandingBannerImg}
            alt="shadowcast"
            className="w-full h-auto"
            onLoad={onBannerImageLoad}
            style={{ display: bannerImageLoaded ? "block" : "none" }}
          />
        </picture>
        <div className="container app-container m-auto">
          <div className="hero-text-container">
            <h1 className="gen-hero-text myFont">
              The World’s First AI Movie Star
            </h1>
            <p className="gen-hero-text-desc">
              As the world’s first AI Movie Star, Model, Influencer and Brand
              Ambassador for Fourth Star, Genevieve is more than a virtual
              character; she's a phenomenon. She’s an AI-powered virtual
              superstar designed to redefine immersive storytelling and digital
              entertainment. Think of her as the bridge between technology and
              art, a celebrity born out of cutting-edge artificial intelligence
              and breathtaking computer graphics.
            </p>
          </div>

          <div className="hero-image-container">
            <picture className="">
              <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
              <ShadowFrame className="absolute top-2/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />

              <img src={heroImage2} className="h-auto w-screen" alt="" />
            </picture>

            <div className="shadow-frame hero-shadow"></div>
          </div>

          <div className="self-stretch flex flex-row items-start justify-center my-5 mx-0 p-0 box-border max-w-full">
            <div className="flex flex-col items-center justify-center gap-[30px] w-screen">
              <h1 className="styled-text myFont">#TheDigitalDivaForANewEra</h1>
              {/* <div className="self-stretch flex flex-row items-center justify-center py-0 box-border max-w-full text-base text-white font-montserrat">
              <div className="myFont relative leading-[150%] font-medium inline-block shrink-0 max-w-full">
              Meet the World's First Virtual Superstar.
              </div>
            </div> */}
              <div className="relative w-full pb-[56.25%] h-0">
                {/* <video
                  ref={videoRef}
                  className="absolute top-0 left-0 w-full h-full rounded-lg cursor-pointer"
                  preload="none"
                  // muted
                  controls
                  poster={intro_video_preview}
                  onPlay={handlePlay}
                  onPause={handlePause}
                >
                  <source src={video_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}

                <img
                  src={gen_last}
                  className="absolute top-0 left-0 w-full h-full"
                />

                {/* {!isPlaying && ( */}
                <div
                  // onClick={handleVideoClick}
                  className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50"
                >
                  <div className="self-stretch flex flex-row items-center justify-center py-0 box-border max-w-full text-base text-center text-white font-montserrat">
                    <div className="myFont relative text-sm sm:text-xl md:text-3xl leading-[150%] font-medium inline-block shrink-0 max-w-full md:max-w-[70%] ">
                      Designed for the digital age, built for the big screen,
                      Genevieve is the world’s first AI Movie Star.
                    </div>
                  </div>
                  {/* <div className="bg-transparent p-4 rounded-full shadow-lg">
                      <svg
                        width="68"
                        height="68"
                        viewBox="0 0 68 68"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="68" height="68" rx="34" fill="white" />
                        <path
                          d="M22.6665 17L50.9998 34L22.6665 51V17Z"
                          fill="black"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="self-stretch flex flex-row items-center justify-center py-0 box-border max-w-full text-base text-center text-[#ffffffaf] font-montserrat">
                      <div className="myFont relative leading-[150%] text-sm font-thin inline-block shrink-0 max-w-full md:max-w-[30%] ">
                        Watch her in action
                      </div>
                    </div> */}
                </div>
                {/* )} */}
              </div>
            </div>
          </div>

          <div className=" myFont my-20 md:mt-32 ">
            <h1 className="styled-text myFont  w-full">
              #World'sFirstVirtualSuperstar
            </h1>
            {/* <h1 className="hero-text myFont">Coming Soon to Social Media</h1> */}
            <div className="w-[70%] m-auto ">
              <div className="flex justify-center flex-col gap-5">
                <h1 className="hero-text myFont">
                  Coming Soon to Social Media
                </h1>
                <p className="gen-hero-text-desc w-[70%] m-auto">
                  Get ready to connect with Genevieve! The world’s first virtual
                  superstar is about to take over social media. Stay tuned!
                </p>
              </div>
              <div>
                <img src={social_hidden} alt="" className="h-auto w-full" />
              </div>
            </div>
            {/* <div className="grid mx-20 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 "> */}
            {/* <div className="group relative z-10 flex flex-col items-center justify-center">
                <a
                  href={socialLinks.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group inline-block"
                >
                  <FaFacebook size={32} className="hover:text-[#FFDF62]" />
                </a>

                <img
                  src={genevive_social_1}
                  alt=""
                  className="h-auto mt-10 w-full object-cover group-hover:scale-105 transition duration-300 ease-in-out"
                />
              </div> */}
            {/* <div className="group relative z-10 flex flex-col items-center justify-center">
                <a
                  href={socialLinks.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group inline-block"
                >
                  <FaInstagram size={32} className="hover:text-[#FFDF62]" />
                </a>

                <img
                  src={genevive_social_2}
                  alt=""
                  className="h-auto mt-10 w-full object-cover group-hover:scale-105 transition duration-300 ease-in-out"
                />
              </div>
              <div className="group relative z-10 flex flex-col items-center justify-center">
                <a
                  href={socialLinks.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter size={32} className="hover:text-[#FFDF62]" />
                </a>

                <img
                  src={genevive_social_3}
                  alt=""
                  className="h-auto mt-10 w-full object-cover group-hover:scale-105 transition duration-300 ease-in-out"
                />
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </Box>
    </>
  );
};

export default Index;
